<template lang="pug">
include ../../../configs/template.pug
div
  form
    div
      +select-validation('body.service_center', 'listBranchOffice', 'affiliate', 'nameLang', '["required"]')
    div
      +select('body.rank', 'listRank', 'rank', 'nameLang')(readonly item-value="id")
    div
      +select('body.position', 'listPosition', 'position', 'nameLang')(readonly multiple item-value="id")
    div
      +select('body.include_sailor_passport', 'listProcessingStatuses', 'sailorPassport', 'nameLang',)(readonly item-value="id")
    div(v-if="checkRank")
      +select-validation('body.sqc_type', 'filterListTypeSQC', 'typeSQC', 'nameLang', '["required"]')(return-object)
    div(v-if="body?.sqc_type?.value === '0'")
      +select-validation('body.type_exam', 'listTypeExam', 'typeExam', 'nameLang', '["required"]')
    div(v-if="sailorIsCadet && ([23, 86, 90]).includes(body.rank)")
      +checkbox('body.education_with_sqc', 'educationWithSQC')
  PreviewPackage(
    v-if="Object.keys(invalidPackageInfo).length"
    ref="positionStatementPreview"
    :invalidPackageInfo="invalidPackageInfo")
  div.text-center
    v-btn(v-if="invalidPackageInfo.can_create_packet" color="success" @click="createPacket" :loading="isLoading") {{ $t('btn.save') }}
    v-btn(v-else color="success" @click="checkPreviewPacket" :loading="isLoading") {{ $t('checkValidPackage') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { createPositionPacketISSC } from '@/mixins/validationRules.js'
import { TYPE_CONDITIONAL_CONSTANTS, TYPE_DOCUMENT_TITLE } from '@/configs/constants'
import { clearBody } from '@/mixins/main'
const initBody = () => (
  {
    service_center: null,
    education_with_sqc: false,
    position: [],
    include_sailor_passport: null,
    sailor_id: null,
    sqc_type: null,
    type_exam: null,
    rank: null,
    is_payed: false
  }
)
export default {
  name: 'CreatePositionPackage',
  components: {
    PreviewPackage: () => import('@/views/Sailor/SailorPositionStatement/SailorPositionStatementPreview.vue')
  },
  props: { statement: { type: Object, default: () => ({}) } },
  data () {
    return {
      body: initBody(),
      invalidPackageInfo: {},
      packageIsContinue: {},
      isLoading: false
    }
  },
  validations () { return createPositionPacketISSC(this) },
  watch: {
    'body.service_center' (newVal, oldVal) {
      if (newVal !== oldVal) this.invalidPackageInfo = {}
    },
    'body.sqc_type' (newVal, oldVal) {
      if (newVal !== oldVal) this.body.type_exam = null
    }
  },
  computed: {
    ...mapGetters(['availablePositionsById', 'getDirectoryObject', 'sailorIsCadet']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listBranchOffice: state => state.directory.affiliate.filter(item => !item.is_disable),
      listRank: state => state.sailor.ranks,
      listPosition: state => state.sailor.positions,
      listTypeSQC: state => state.directory.listTypeSQC.filter(el => ![1, 4, 5].includes(el.id)),
      listTypeExam: state => state.directory.listTypeExam,
      listProcessingStatuses: state => state.directory.processingStatuses
    }),
    filterListTypeSQC () {
      let excludeValue = [1, 4, 5]
      this.packageIsContinue?.is_continue !== TYPE_CONDITIONAL_CONSTANTS.CONFIRM_RANK_SQC && excludeValue.push(2)
      return this.listTypeSQC.filter(el => !excludeValue.includes(el.id))
    },
    checkRank () {
      return this.getDirectoryObject({ id: this.body.rank, value: 'ranks' })?.is_dkk || false
    }
  },
  mounted () {
    Object.keys(this.body).forEach(key => (this.statement[key] && (this.body[key] = this.statement[key])))
    this.checkIsContinue(this.body.position, this.statement.sailor.id)
  },
  methods: {
    ...mapActions(['checkIsContinueForPacket', 'previewForPacket', 'setPacketISC']),
    async checkPreviewPacket () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      // eslint-disable-next-line camelcase
      const { service_center, education_with_sqc, position } = this.body
      this.invalidPackageInfo = await this.previewForPacket({
        sailorId: this.statement.sailor.id,
        body: {
          service_center,
          education_with_sqc,
          position
        }
      })
      this.isLoading = false
    },
    async checkIsContinue (position, sailorId) {
      this.packageIsContinue = await this.checkIsContinueForPacket({ sailorId, body: { position } })
    },
    async createPacket () {
      this.isLoading = true
      let body = {
        ...this.body,
        sailor_id: this.statement.sailor.id,
        statement_packet_item: this.statement.id
      }
      if (this.body.sqc_type) {
        if (this.body.sqc_type.is_continue) body.sqc_type = this.body.sqc_type.value
        else {
          delete body.sqc_type
          body.ces_exam = this.body.sqc_type.ces_exam
        }
      }
      const { MEDICAL, ETI_CERTIFICATE } = TYPE_DOCUMENT_TITLE
      if (this.$refs.positionStatementPreview?.previewInformation.documents_not_exists?.some(item => ETI_CERTIFICATE.includes(item.type_document))) {
        body.dependency_documents_ntz = this.$refs.positionStatementPreview.previewInformation.documents_not_exists.reduce((acc, cur) => {
          if (ETI_CERTIFICATE.includes(cur.type_document) && cur.institution) acc.push({ dependency_document: cur.id, ntz: cur.institution })
          return acc
        }, [])
      }
      if (this.$refs.positionStatementPreview?.previewInformation.documents_not_exists?.some(item => item.type_document === MEDICAL)) {
        let medical = this.$refs.positionStatementPreview?.previewInformation.documents_not_exists
        medical.forEach((item) => {
          if (item.type_document === MEDICAL) {
            body.medical_institution = item.medical_institution
            body.medical_position = item.medical_position
          }
        })
      }
      const response = await this.setPacketISC({ body: { ...clearBody({ ...body }) } })
      if (response) {
        this.$parent.$parent.getData()
        this.$notification.success('notify.success.paketCreate')
        this.body = initBody()
        this.$v.$reset()
      }
      this.isLoading = false
    }
  }
}
</script>
